<template>
    <p class="p-zensavvy">{{translations.first_step.title}}</p>
    <p class="zensavvy-error" v-for="error in errors.services.$errors">
      {{ error.$message }}  
    </p>

    <div class="option" v-for="(option, index) in services"
        :key="index" :class="{ selected: submission.services.includes(option.slug) }" @click="toggleOption(option.slug)">
            <span class="option-circle p-zensavvy">{{ getLabel(index) }}</span>
            <span class="option-text p-zensavvy">{{ option.name }}</span>
            <span v-if="submission.services.includes(index)" class="p-zensavvy">
                <i classs="fa-solid fa-check"></i>
            </span>
    </div>

</template>

<script>
export default {
    inject: ['axios', 'locale', 'translations', 'selectedService'],

    props: {
      modelValue: { type: Object, required: true },
      errors: Object
    },

    data: () => ({
      services: [],
    }),

    computed: {
      submission: {
        get() {
          return this.modelValue
        },

        set(value) {
          this.$emit('update:modelValue', value)
        }
      }
    },

    methods: {
        getServices() {
          this.services = this.translations.services

          if (this.selectedService) {
            let found = this.services.find(s => s.slug === this.selectedService.slug)
            
            if (found) this.submission.services.push(found['slug'])
          }
        },

        toggleOption(slug) {
            if (this.submission.services.includes(slug)) {
                this.submission.services = this.submission.services.filter(i => i !== slug);
            } else {
                this.submission.services.push(slug);
            }
        },

        getLabel(index) {
            let labels = ['A', 'B', 'C', 'D', 'E', 'F', 'G']
            return labels[index]
        }
    },

    mounted() {
      this.getServices()
    },
}
</script>

<style scoped>
.options-container {
  background-color: #1e0149;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  margin: 0 auto;
  color: white;
}

.option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  border: 2px solid #585a88;
  border-radius: 30px;
  margin-bottom: 15px;
  transition: all 0.3s;
  cursor: pointer;
}

.option:hover {
  border-color: #a8b3e4;
}

.option-circle {
  background-color: transparent;
  border: 2px solid #585a88;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  transition: all 0.3s;
}

.option-text {
  flex: 1;
  padding-left: 10px;
}

.selected {
  background-color: #5f5cba;
  border-color: #5f5cba;
}

.selected .option-circle {
  background-color: white;
  color: #5f5cba;
}

.submit-btn {
  width: 100%;
  padding: 15px;
  border-radius: 30px;
  background-color: #a44de5;
  color: white;
  font-size: 18px;
  cursor: pointer;
  border: none;
  text-align: center;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submit-btn:hover {
  background-color: #9639d6;
}
</style>