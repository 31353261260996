import App from '@components/contact_form/App.vue'
import { generateApplication } from '@helpers/generate_component.js';
import { generateAxiosInstance } from '@helpers/axios_provider.js'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueCookies from 'vue-cookies';

generateApplication(
  App,
  "#contact-form-app",
  {
    injections: [{
      name: 'axios', module: generateAxiosInstance(hostApiKey, 'https://zensavvy.com/api/v1/pub/')
    }, {
      name: 'locale', module: locale
    }, {
      name: 'translations', module: translations
    }, {
      name: 'selectedService', module: typeof selectedService != 'undefined' ? selectedService : null
    }],
    packages: [autoAnimatePlugin, VueSweetalert2, VueCookies]
  }
)